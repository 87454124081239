import axios from '../../plugins/axios';
export default {
    namespaced: true,
    state() {
        return {
            fileData: {
                page: 1,
                limit: 20,
                list: [],
                total: 0
            },
        }
    },
    mutations: {
        // 初始化商品列表
        fileListInit(state, fileData) {
            state.fileData = fileData;
        },
    },
    actions: {
        // 请求banner列表
        // params={page,size}
        getFileListAction(context,) {
            console.log(this);
            let page = this.state.file.fileData.page
            let limit = this.state.file.fileData.limit
            axios.get(`/course/?page=${page}&limit=${limit}`).then(res => {
                if (res.code === 200) {
                    var resData = res.data.list
                    console.log('------------------哈哈哈哈哈');
                    console.log(resData);
                    for (let index = 0; index < resData.length; index++) {
                        const element = resData[index];
                        element.create_time = element.create_time.replace('T', ' ')
                    }
                    res.data.list = resData
                    // 更新商品列表
                    context.commit('fileListInit', res.data);
                }
            });
        },
    }
}