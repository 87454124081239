import { Message } from "element-ui";
import axios from "../../plugins/axios";
export default{
    namespaced:true,
    state(){
        return{
            // 活动数据
            tableData: {
                page: 1,
                limit: 20,
                list: [],
                total: 0
            },
        }
    },
    mutations:{
        // 初始化活动列表
        goodsInit(state,tableData){
            state.tableData=tableData;
            console.log('调用初始化活动');
            console.log(state.tableData);
        },
    },
    actions: {
        getGoods(context,keyword=null) {
            console.log(this);
            let page = this.state.goods.tableData.page
            let limit = this.state.goods.tableData.limit
            console.log('这里的page是：' + page + 'limit是：' + limit);
            var url = `/feedback/?page=${page}&limit=${limit}`
            if (keyword != null) {
                url = `/feedback/?keyword=${keyword}&page=${page}&limit=${limit}`
            }
            axios.get(url).then(res=>{
                if (res.code == 200) {
                    console.log('请求活动数据的返回值：');
                    console.log(res);
                    var resData = res.data.list
                    console.log('------------------哈哈哈哈哈');
                    console.log(resData);
                    for (let index = 0; index < resData.length; index++) {
                      const element = resData[index];
                      element.create_time = element.create_time.replace('T', ' ')
                    }
                    res.data.list = resData
                    context.commit('goodsInit',res.data)
                }else {
                    console.log("返回的不是200");
                }
            })
        }
    }
}