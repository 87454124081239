import axios from '../../plugins/axios';
export default {
    namespaced: true,
    state() {
        return {
            itemsData: [],
            goodsList: []
        }
    },
    mutations: {
        // 初始化商品列表
        itemsInit(state, itemsData) {
            state.itemsData = itemsData;
        },

        goodsInit(state, goodsData) {
            state.goodsList = goodsData;
        },
    },
    actions: {
        // 请求banner列表
        // params={page,size}
        getItemsAction(context,) {
            axios.get(`/recommend/`).then(res => {
                if (res.code === 200) {
                    var resData = res.data
                    console.log('------------------哈哈哈哈哈');
                    console.log(resData);
                    for (let index = 0; index < resData.length; index++) {
                        const element = resData[index];
                        element.create_time = element.create_time.replace('T', ' ')

                    }
                    res.data = resData
                    // 更新商品列表
                    context.commit('itemsInit', res.data);
                }
            });
        },
        getGoods(context) {
            var url = `/product/?page=${1}&limit=${200}`
            axios.get(url).then(res => {
                if (res.code == 200) {
                    console.log('请求商品数据的返回值：');
                    console.log(res);
                    var resData = res.data.list
                    console.log('------------------哈哈哈哈哈');
                    console.log(resData);
                    for (let index = 0; index < resData.length; index++) {
                        const element = resData[index];
                        element.create_time = element.create_time.replace('T', ' ')
                    }
                    res.data.list = resData
                     // 更新商品列表
                     context.commit('goodsInit', res.data.list);
                } else {
                    console.log("返回的不是200");
                }
            })
        }
    }
}